import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

import { Footer } from '../components/Footer'

const language = 'sk'
const title = `Pravidlá cookies`

export default function Cookies(): any {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src =
      'https://consent.cookiebot.com/5c417d24-c278-40b7-9df7-4bbafeb70361/cd.js'
    script.id = 'CookieDeclaration'
    script.type = 'text/javascript'

    const cookieBot = document.getElementById('cookiebot')
    if (cookieBot) {
      cookieBot.appendChild(script)
    }

    return () => {
      if (cookieBot) {
        cookieBot.removeChild(script)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
        title={title}
      />

      <div className="cookiesContainer">
        <div className="sectionContainer ">
          <h1 className="cookiesTitle">Pravidlá cookies</h1>
          <div id="cookiebot"></div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  )
}
